<template>
  <div class="sheets">
      <datatable :fetch="$Api.Sheet.fetchSheetsForProject.bind(this, id)" :entity="$EntitiesName.Sheet" ref="sheetList">
        <div slot="name" slot-scope="{ item }" title="Nom">{{ item.name }}</div>
        <div slot="owner" slot-scope="{ item: { owner } }" title="Responsable">{{ owner && owner.name }}</div>
        <div slot="template" slot-scope="{ item: { sheetTemplate } }" title="Type de fiche">{{ sheetTemplate && sheetTemplate.name }}</div>
        <div slot="control-type" slot-scope="{ item: { sheetTemplate } }" title="Type de contrôle budgétaire">{{ sheetTemplate && sheetTemplate.controlType }}</div>
      </datatable>
  </div>
</template>

<script>
import axios from 'axios';
import Datatable from '../../../components/elements/Datatable';

export default {
  name: 'sheets',
  components: {
    Datatable,
  },
  props: ['id'],
  data() {
    return {
      sheet: {},
      sheetTemplateColumns: {
        'project.name': 'Projet',
        name: 'Modèle de fiche affaire',
      },
      sheetStatusColumns: {
        name: 'Jalon',
      },
      ownersColumns: {
        name: 'Utilisateur',
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createSheetModal.open();
    },
    createSheet() {
      const sheet = {
        ...this.sheet,
      };

      if(sheet.oscarSubProjectName) {
        sheet.oscarSubProjectName.sheet = { id: sheet.id }
      }

      axios.post('/sheets', sheet).then((response) => {
        if (response.data.success) {
          this.sheet = {};
          this.$refs.sheetList.refresh();
          this.$refs.createSheetModal.close();
          this.$router.push({
            name: 'Sheet',
            params: { id: response.data.id },
          });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
