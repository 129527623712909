var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sheets"},[_c('datatable',{ref:"sheetList",attrs:{"fetch":_vm.$Api.Sheet.fetchSheetsForProject.bind(this, _vm.id),"entity":_vm.$EntitiesName.Sheet},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{attrs:{"title":"Nom"}},[_vm._v(_vm._s(item.name))])}},{key:"owner",fn:function(ref){
var owner = ref.item.owner;
return _c('div',{attrs:{"title":"Responsable"}},[_vm._v(_vm._s(owner && owner.name))])}},{key:"template",fn:function(ref){
var sheetTemplate = ref.item.sheetTemplate;
return _c('div',{attrs:{"title":"Type de fiche"}},[_vm._v(_vm._s(sheetTemplate && sheetTemplate.name))])}},{key:"control-type",fn:function(ref){
var sheetTemplate = ref.item.sheetTemplate;
return _c('div',{attrs:{"title":"Type de contrôle budgétaire"}},[_vm._v(_vm._s(sheetTemplate && sheetTemplate.controlType))])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }