<template>
  <div class="panel-block is-block">
    <div class="actions">
      <span class="revenues-info">
        <p>Montant total : <strong cy-data="totalIncomes">{{ totalAmount | priceEUR }}</strong></p>
        <p>Montant facturé : <strong>{{ totalInvoiced | priceEUR }}</strong></p>
      </span>
      <span>
        <button
          slot="activator"
          class="button is-warning"
          @click="importMode = !importMode"
          v-if="!importMode"
          >
          Importer des BDC
        </button>
        <button
          @click.stop="openOrderModal"
          slot="activator"
          class="button is-warning"
          v-if="!importMode"
          >
          Ajouter un BDC
        </button>
        <button class="button is-warning" v-if="importMode" @click="cancel">Annuler</button>
        <button class="button is-warning" v-if="importMode" :disabled="!valid" @click="importOrders" :title="valid ? '' : 'Des lignes sont en erreur. Corrigez les pour pouvoir importer.'">Importer</button>
      </span>
    </div>

    <div v-if="!importMode">
      <table class="table order-table" style="width: 100%">
        <thead>
          <tr>
            <th class="has-text-left" style="width: 20%">Catégorie</th>
            <th class="has-text-left" style="width: 20%">N° BDC/Devis</th>
            <th class="has-text-left" style="width: 15%">Imputation</th>
            <th class="has-text-right" style="width: 15%">Montant</th>
            <th class="has-text-right" style="width: 15%">Facturé</th>
            <th style="width: 5%"></th>
          </tr>
        </thead>
      </table>

      <template v-for="(order, index) in orders">
        <OrderBreakdown :key="'order_' + index + key" v-model="orders[index]" @updated="fetchData"/>
      </template>
    </div>

    <div class="block has-background-warning-light error-message" v-if="importMode && importErrors">
      <p class="title is-6">Les lignes suivantes n'ont pas pu être importées :</p>
      <table class="table errors-table has-background-warning-light" style="width: 100%">
        <thead>
          <tr>
            <th class="has-text-left">Ligne</th>
            <th class="has-text-left">Raison</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reason, line) in notImported" :key="'ni_' + line">
            <td>{{ Number(line) + 1 }}</td>
            <td>{{ reason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <OrdersImport v-if="importMode" :columns="handsonColumns" v-model="importData" @valid="valid=$event" />
    <!-- <handsontable v-if="importMode" :columns="handsonColumns" :initialData="importData" :startRows="1" :displayStatus="false" @input="setImportData($event)"/> -->

    <modal ref="addOrderModal" @create="addOrder" title="Ajouter un bon de commande à un projet">
      <!-- category -->
      <ValidationObserver ref="form">
      <many2one-field
        label="Catégorie"
        v-model="newOrder.category"
        :fetch="$Api.Category.fetchParentCategories"
        reference="name"
        :entity="$EntitiesName.Category"
        :columns="{ 'name': 'Catégorie' }"
        :hasFilter="true"
        :inline="false"
        :edit="true"
        required
      ></many2one-field>
      <text-field label="Montant" type="number" v-model="newOrder.amount" :inline="false" :edit="true" required rules="required"/>
      <text-field label="Numéro de commande" v-model="newOrder.orderNumber" :inline="false" :edit="true" required rules="required"/>
      </ValidationObserver>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import OrderBreakdown from '../../../components/elements/OrderBreakDown.vue'
import OrdersImport from '../../imports/Orders'

export default {
  components: { OrderBreakdown, OrdersImport },
    name: 'revenues-tab',
    props: {
      id: Number,
      value: Array,
      project: Object
    },
    data() {
      return {
        importMode: false,
        orders: [],
        newOrder: {
          ordered: true
        },
        key: 0,
        importData: [],
        handsonColumns: [
          {
            label: 'Catégorie',
            key: 'category',
            required: true,
          },
          {
            label: 'N° BDC',
            key: 'orderNumber',
            required: true
          },
          {
            label: 'Imputation',
            key: 'eotp',
          },
          {
            label: 'Montant',
            key: 'amount',
            type: 'numeric',
            required: true,
          },
          {
            label: 'Facturé',
            key: 'invoiced',
            type: 'numeric',
          },
        ],
        valid: true,
        notImported: {}
      }
    },
    computed: {
      totalAmount() {
        let total = 0
        this.orders.forEach(o => {
          total += o.ordered ? o.amount : 0
        })
        return total
      },
      totalInvoiced() {
        // if(!this.orders && !this.orders.orderBreakdown) return

        let total = 0
        this.orders.forEach(o => {
          o.orderBreakdowns.forEach(ob => {
            total += parseFloat(ob.invoiced)
          })
        })
        return total
      },
      importErrors() {
        return Object.keys(this.notImported).length > 0
      }
    },
    mounted() {
      if(!this.project) return

      this.newOrder.project = this.project

      this.fetchData()
    },
    watch: {
      orders(val) {
        this.$emit('input', val)
        this.resetImportData(val)
      }
    },
    methods: {
      fetchData() {
        this.$Api.Order.fetchOrdersForProject(this.id, (data) => {
          let categories = {}

          data.forEach((o) => {
            if(!categories[o.category.name]) categories[o.category.name] = []

            categories[o.category.name].push(o)
          })

          if(Object.keys(categories).length === 0) {
            this.orders = []
            return
          }

          this.orders = Object.values(categories).reduce((acc, cat) => [].concat(acc, cat))
          this.key += 1
        })
      },
      openOrderModal() {
        this.$refs.addOrderModal.open();
      },
      addOrder() {
        this.$refs.form.validate().then(success => {
          if (!success) {
            return;
          }

          axios
            .post('/orders', this.newOrder)
            .then(() => {
              this.newOrder = {
                ordered: true,
                project: this.project
              };
              this.$refs.addOrderModal.close();
              this.fetchData()
            });
        })
      },
      resetImportData(val) {
        this.importData = []

        val.forEach(o => {
          if(!o.ordered) return

          this.importData.push({
            category: o.category.name,
            orderNumber: o.orderNumber,
            eotp: '',
            amount: o.amount,
            invoiced: 0
          })
          o.orderBreakdowns.forEach(ob => {
            this.importData.push({
              category: o.category.name,
              orderNumber: o.orderNumber,
              eotp: ob.imputation.eotp,
              amount: ob.amount,
              invoiced: ob.invoiced
            })
          })
        })
      },
      setImportData(data) {
        this.importData = []

        data.forEach((rowData) => {
          let obj = {};

          if(this.allowExtraColumns) {
            obj['extraColumns'] = {}
          }

          for(let i = 1; i < rowData.length; i++) {
            // Check for extra columns
            let key = this.handsonColumns[i].key;
            obj[key] = rowData[i];
          }
          this.importData.push(obj);
        })
      },
      cancel() {
        this.notImported = {}
        this.importMode = false
      },
      importOrders() {
        this.notImported = {}
        const importData = this.importData
        .map((row, idx) => {
          row.index = idx
          return row
        }).filter((r) => r.amount || r.category || r.eotp || r.invoiced || r.orderNumber)
        return axios.post(`/projects/${this.project.id}/orders`, importData).then((response) => {
          if (response.data.invalid.length === 0 && Object.keys(response.data.error).length === 0) {
            this.fetchData()
            this.importMode = false
          }

          else {
            this.notImported = response.data.error
          }
        });
      },
    }
}
</script>

<style scoped lang="scss">
  .actions {
    display:flex;
    justify-content: space-between;
    button {
      margin-left: 5px;
    }
  }
  .revenues-info {
    display: flex;
    align-items: center;
    &>* {
      margin-right: 20px;
      vertical-align: middle;
    }
  }

.order-table {
  margin-top: 20px;
  margin-bottom: 0px;

  th {
    text-align: center;
  }
}
.errors-table {
  td {
    padding: 0.2em 0.75em;
  }
}

.error-message {
  margin-top: 1.5rem;
  padding: 10px 2em;
}

</style>
