<template>
  <div class="panel-block is-block">
    <div class="is-pulled-right" v-if="!editMode">
      <button
        @click.stop="openSubPrestationModal"
        slot="activator"
        class="button is-success"
      >
        <font-awesome-icon icon="plus" />
      </button>
    </div>

    <table class="table" style="width: 100%" v-if="!editMode">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th>Hypothèse APS</th>
          <th>Unité APS</th>
          <th>Hypothèse EXE</th>
          <th>Unité EXE</th>
          <th>Prix</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(category, catName) in sortedPrestations">
          <tr :key="catName">
            <td class="is-info">{{ catName }}</td>
            <td class="is-info"></td>
            <td class="is-info"></td>
            <td class="is-info"></td>
            <td class="is-info"></td>
            <td class="is-info"></td>
            <td class="is-info"></td>
            <td class="is-info"></td>
          </tr>
          <template v-for="(prestation, prestName) in category">
            <tr :key="prestName">
              <td class="prestation">{{ prestation.subCategory }}</td>
              <td class="prestation">{{ prestName }}</td>
              <td>{{ prestation.subPrestationName }}</td>
              <td class="has-text-centered">{{ prestation.hypothesisAps | percentage }}</td>
              <td>{{ prestation.oscarColumnAps }}</td>
              <td class="has-text-centered">{{ prestation.hypothesisExe | percentage }}</td>
              <td>{{ prestation.oscarColumnExe }}</td>
              <td class="has-text-right">{{ prestation.price | priceEUR }}</td>
            </tr>
            <template v-for="(subPrestation, idx) in prestation.subPrestations">
            <tr :key="prestName + idx">
              <td></td>
              <td></td>
              <td>{{ subPrestation.subPrestationName }}</td>
              <td class="has-text-centered">{{ subPrestation.hypothesisAps | percentage }}</td>
              <td>{{ subPrestation.oscarColumnAps }}</td>
              <td class="has-text-centered">{{ subPrestation.hypothesisExe | percentage }}</td>
              <td>{{ subPrestation.oscarColumnExe }}</td>
              <td class="has-text-right">{{ subPrestation.price | priceEUR }}</td>
            </tr>
          </template>
          </template>
        </template>
      </tbody>
    </table>

    <ImportSubPrestations v-if="editMode" v-model="prestations" :columns="handsonColumns" />

    <modal ref="addSubPrestationModal" @create="addSubPrestation" title="Ajouter des prestations à un projet">
      <!-- prestation -->
      <many2one-field
        label="Prestation"
        v-model="subPrestations.prestation"
        :fetch="$Api.Prestation.fetchPrestations"
        reference="name"
        :entity="$EntitiesName.prestation"
        :columns="{ 'category.parent.name': 'Catégorie', 'category.name': 'Sous-Catégorie', name: 'Nom' }"
        :hasFilter="true"
        :inline="false"
        :edit="true"
        required
      ></many2one-field>
      <!-- oscar prestation -->
      <label class="label"><strong>Sous-Prestations</strong></label>
      <datatable
        v-if="subPrestations.prestation"
        :fetch="$Api.SubPrestationName.fetchSubPrestationNames"
        v-model="subPrestations.subPrestationNames"
        :columns="{ name: 'Nom' }"
        :filterColumns="true"
        :size="15"
      ></datatable>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import ImportSubPrestations from '../../imports/SubPrestations'

export default {
  name: 'project-prestations-tab',
  components: {
    ImportSubPrestations
  },
  props: {
    value: Array,
    project: Object
  },
  data() {
    return {
      prestationColumns: [
        {
          title: 'Catégorie',
          name: 'category',
        },
        {
          title: 'Sous-catégorie',
          name: 'sub-category',
        },
        {
          title: 'Prestation',
          name: 'prestation',
        },
        {
          title: 'Sous-prestation',
          name: 'sub-prestation',
        },
        {
          title: 'Hypothèse APS',
          name: 'hypothesisAps',
          class: 'has-text-centered',
        },
        {
          title: 'Unité APS',
          name: 'columnOscarAps',
        },
        {
          title: 'Hypothèse EXE',
          name: 'hypothesisExe',
          class: 'has-text-centered',
        },
        {
          title: 'Unité EXE',
          name: 'columnOscarExe',
        },
        {
          title: 'Colonne OSCAR',
          name: 'oscar-column',
        },
        {
          title: 'Prix',
          name: 'price',
          class: 'has-text-right',
        },
      ],
      subPrestations: {
        subPrestationNames: [],
      },
      prestations: [],
      handsonColumns: [
        {
          label: 'Catégorie',
          key: 'category',
          readOnly: true
        },
        {
          label: 'Sous-catégorie',
          key: 'subCategory',
          readOnly: true
        },
        {
          label: 'Prestation',
          key: 'prestation',
          readOnly: true
        },
        {
          label: 'Sous-prestation',
          key: 'subPrestationName',
          readOnly: true
        },
        {
          label: 'Hypothèse APS',
          key: 'hypothesisAps',
          type: 'numeric'
        },
        {
          label: 'Unité APS',
          key: 'oscarColumnAps',
          type: 'autocomplete',
          source: (q, p) => p(this.oscarColumnNames),
          strict: true,
          allowInvalid: false
        },
        {
          label: 'Hypothèse EXE',
          key: 'hypothesisExe',
          type: 'numeric'
        },
        {
          label: 'Unité EXE',
          key: 'oscarColumnExe',
          type: 'autocomplete',
          source: (q, p) => p(this.oscarColumnNames),
          strict: true,
          allowInvalid: false
        },
        {
          label: 'Prix',
          key: 'price',
          type: 'numeric'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      editMode: 'App/isEdition',
      oscarColumns: "App/getOscarColumns",
    }),
    ...mapActions({
      loadOscarColumns: "App/loadOscarColumns",
    }),
    oscarColumnNames() {
      return this.oscarColumns ? this.oscarColumns.map(c => c.name) : []
    },
    sortedPrestations() {
      let prestations = {}
      if(!this.prestations) {
        return prestations
      }

      this.prestations.forEach(p => {
        if(!prestations[p.category]) {
          prestations[p.category] = {}
        }
        if(!prestations[p.category][p.prestation]) {
          prestations[p.category][p.prestation] = {
            subCategory: p.subCategory,
            subPrestationName: p.subPrestationName,
            hypothesisAps: p.hypothesisAps,
            oscarColumnAps: p.oscarColumnAps,
            hypothesisExe: p.hypothesisExe,
            oscarColumnExe: p.oscarColumnExe,
            price: p.price,
            subPrestations: []
          }
        } else {
          prestations[p.category][p.prestation].subPrestations.push(p)
        }
      });

      return prestations
    }
  },
  mounted() {
    if(this.project) {
      this.subPrestations.project = this.project
    }
    this.fetchData()
    this.loadOscarColumns
  },
  watch: {
    prestations(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    fetchData() {
      this.$Api.Project.fetchSubPrestationsForProject(this.project.id, (data) =>
        this.prestations = data
      )
    },
    openSubPrestationModal() {
      this.$refs.addSubPrestationModal.open();
    },
    addSubPrestation() {
      axios
        .post('/sub-prestations/create', this.subPrestations)
        .then(() => {
          this.subPrestations = { project: this.project };
          this.$refs.addSubPrestationModal.close();
          this.fetchData()
        });
    },
  }

}
</script>

<style scoped lang="scss">
.table {
  th,
  td {
    vertical-align: middle;
  }
}
.prestation {
  font-weight: bold;
}
</style>
