<template>
  <table class="table breakdown-table" style="width: 100%">
    <tbody>
      <tr class="order has-background-light" :class="{ 'has-text-dark': order.orederd, 'has-text-warning': !order.ordered }" @click="editBreakdown">
        <td style="width: 20%">
          <span>
            <font-awesome-icon icon="angle-down" />
            {{ order.category && order.category.name }}
          </span>
        </td>
        <td style="width: 20%" cy-data="orderNumber">{{ order.orderNumber }}</td>
        <td style="width: 15%">
        </td>
        <td style="width: 15%" class="has-text-right" :class="{ 'has-text-danger': amountLeft < 0 }" cy-data="orderAmount">{{ order.amount | priceEUR }}</td>
        <td style="width: 15%" class="has-text-right">{{ totalInvoiced | priceEUR }}</td>
        <td class="has-text-right" style="width: 5%">
          <button
            v-if="!editMode && getRights($EntitiesName.Order).update && !order.ordered"
            class="button is-small is-info"
            @click.stop="openOrderModal()"
            cy-data="transformButton"
          >
            <font-awesome-icon icon="exchange-alt" />
          </button>
          <button
            v-if="!editMode && getRights($EntitiesName.Order).delete"
            class="button is-small is-danger"
            @click.stop="deleteOrder()"
            cy-data="deleteButton"
          >
            <font-awesome-icon icon="trash-alt" />
          </button>
        </td>
      </tr>
      
      <!-- BREAKDOWNS -->
      <template v-if="editMode || breakdownEditMode">
      <tr class="breakdown" v-for="(breakdown, index) in order.orderBreakdowns" :key="'bd_' + index">
        <td></td>
        <td></td>
        <td>
          <many2one-field
            :value="breakdown.imputation"
            @input="updateImputation($event, index)"
            :fetch="$Api.Imputation.fetchImputations"
            reference="eotp"
            :entity="$EntitiesName.Imputation"
            :columns="{ eotp: 'Imputation' , description: 'Description'}"
            :hasFilter="true"
            :inline="false"
            :edit="editMode"
            deleteonly
          ></many2one-field>
        </td>
        <td class="has-text-right">
          <text-field
            type="price"
            :value="breakdown.amount"
            @input="updateAmount($event, index)"
            :edit="editMode"
            :max="Number(breakdown.amount) + amountLeft"
            :min="Number(breakdown.invoiced)"
            :dataTest="order.orderNumber + '_' + breakdown.imputation.description + '_amount'"
          />
        </td>
        <td class="has-text-right">
          <text-field
            v-if="order.ordered"
            type="price"
            :value="breakdown.invoiced"
            @input="updateInvoiced($event, index)"
            :edit="editMode"
            :dataTest="order.orderNumber + '_' + breakdown.imputation.description + '_invoiced'"
          />
          <field
            v-if="!order.ordered"
            type="price"
            :value="breakdown.invoiced"
            :dataTest="order.orderNumber + '_' + breakdown.imputation.description + '_invoiced'"
            readonly
          />
        </td>
        <td></td>
      </tr>

      <!-- ADDITIONAL ROW -->
      <tr class="breakdown" v-if="amountLeft !== 0">
        <td></td>
        <td></td>
        <td class="">
          <many2one-field
            :fetch="$Api.Project.fetchImputationsAvailableForOrder.bind(this, project.id, imputationsToExclude)"
            :value="[]"
            @input="updateImputation($event)"
            reference="eotp"
            :entity="$EntitiesName.Imputation"
            :columns="{ eotp: 'Imputation' , description: 'Description'}"
            :hasFilter="true"
            :inline="false"
            :edit="editMode"
            :dataTest="order.orderNumber + '_addImputationButton'"
          ></many2one-field>
        </td>
        <td class="has-text-right">{{ amountLeft | priceEUR }}</td>
        <td class="has-text-right"></td>
        <td></td>
      </tr>
      </template>
    </tbody>

    <modal ref="addOrderModal" @create="addOrder" title="Transformer un devis en bon de commande">
      <!-- category -->
      <many2one-field
        label="Catégorie"
        v-model="newOrder.category"
        :fetch="$Api.Category.fetchParentCategories"
        reference="name"
        :entity="$EntitiesName.Category"
        :columns="{ 'name': 'Catégorie' }"
        :hasFilter="true"
        :inline="false"
        :edit="true"
        required
      ></many2one-field>
      <text-field label="Montant" type="number" v-model="newOrder.amount" :inline="false" :edit="true" required rules="required"/>
      <text-field label="Numéro de commande" v-model="newOrder.orderNumber" :inline="false" :edit="true" required rules="required"/>
    </modal>
  </table>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';

export default {
  name: 'order-breakdown',
  props: {
    value: Object
  },
  data() {
    return {
      originalBreakdowns: [],
      order: {},
      breakdownEditMode: false,
      newBreakdown: {
        imputation: null,
        amount: null,
        invoiced: null
      },
      newOrder: {}
    }
  },
  computed: {
    ...mapGetters({
      getRights: 'Auth/getRights',
      editMode: 'App/isEdition'
    }),
    project() {
      return this.order.project ? this.order.project : {}
    },
    totalInvoiced() {
      if(!this.order.orderBreakdowns) return

      let total = 0
      this.order.orderBreakdowns.forEach(ob => {
        total += ob.invoiced
      })
      return total
    },
    imputationsToExclude() {
      if(!this.order.orderBreakdowns) return []
      let imputations = []
      this.order.orderBreakdowns.forEach(ob => {
        imputations.push(ob.imputation.id)
      })
      return imputations
    },
    amountLeft: {
      get() {
        if(this.newBreakdown.amount) return this.newBreakdown.amount

        let amount = 0
        if(this.order.orderBreakdowns) {
          this.order.orderBreakdowns.forEach(b => {
            amount += Number(b.amount)
          });
        }

        return Number((this.order.amount - amount).toFixed(2))
      },
      set(val) {
        this.newBreakdown.amount = Number(val)
      }
    }
  },
  watch: {
    editMode() {
      this.reset()
    },
    'order.orderBreakdowns'() {
      this.$emit('input', this.order)
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if(!this.value) return

      this.originalBreakdowns = this.value.orderBreakdowns.map(bd => JSON.parse(JSON.stringify(bd)))
      this.order = JSON.parse(JSON.stringify(this.value))
      this.newOrder = JSON.parse(JSON.stringify(this.value))
      this.newOrder.orderNumber = null
      this.newOrder.ordered = true
    },
    reset() {
      this.order.orderBreakdowns = this.originalBreakdowns.map(bd => JSON.parse(JSON.stringify(bd)))
    },
    editBreakdown() {
      this.breakdownEditMode = !this.breakdownEditMode
    },
    updateImputation(val, idx) {
      if(!val) {
        this.order.orderBreakdowns.splice(idx, 1)
        return
      }

      const amountByImputation = Number((this.amountLeft / val.length).toFixed(2))
      if(val.length === 0) return

      val.forEach(imputation => {
        this.order.orderBreakdowns.push(
          { 
            imputation: imputation,
            amount: amountByImputation,
            invoiced: 0,
            order: { id: this.order.id } 
          }
        )
      })
    },
    updateAmount(val, idx) {
      if(val < this.order.orderBreakdowns[idx].invoiced) return //val = this.order.orderBreakdowns[idx].invoiced
      if(Number((val - this.order.orderBreakdowns[idx].amount).toFixed(2)) > this.amountLeft) return

      this.order.orderBreakdowns[idx].amount = val
    },
    updateInvoiced(val, idx) {
      this.order.orderBreakdowns[idx].invoiced = Number(val)
    },
    deleteOrder() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/orders/${this.order.id}`).then(() => {
              this.$emit('updated');
          })
      );
    },
    openOrderModal() {
      this.$refs.addOrderModal.open();
    },
    addOrder() {      
      axios
        .put('/orders/' + this.order.id, this.newOrder)
        .then(() => {
          this.$emit('updated')
        });
    },
  }
}
</script>

<style scoped>
  .table td {
    vertical-align: middle;
  }
  .button {
    margin-left: 4px;
  }
  .breakdown-table {
    margin: 0;
    border-top: 1px solid #DDD;
  }
  .order {
    font-weight: bold;
    cursor: pointer;
  }
</style>