<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Project" :edit="edit" :save="save" :cancel="cancel" :remove="remove">
        <h1 class="title" data-test="sel-project-header">
          <breadcrumb location="/projects" text="Projet"></breadcrumb>
          {{ project.name }}
        </h1>
        <div slot="actions">
          <button v-if="options.includes($UserOptions.PROJECTS.CLONE)" class="button is-info" :cy-data="`cy-project-clone`" @click="openCloneModal">Cloner</button>
        </div>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': currentTab == 0}" @click="openTab(0)">
                <a>Informations</a>
              </li>
              <li :class="{'is-active': currentTab == 1}" @click="openTab(1)">
                <a>Fiches affaire</a>
              </li>
              <li :class="{'is-active': currentTab == 2}" @click="openTab(2)">
                <a>Recettes</a>
              </li>
              <li :class="{'is-active': currentTab == 3}" @click="openTab(3)">
                <a>Prestations</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="panel-block" v-if="currentTab == 0">
          <div class="column is-half">
            <text-field label="Nom" v-model="project.name" required></text-field>

            <field label="Direction" readonly>
              <span v-if="project.region">{{ project.region.direction.name }}</span>
            </field>
            <many2one-field
              label="Région"
              v-model="project.region"
              :fetch="$Api.Region.fetchRegions"
              reference="name"
              @click="(item) => $router.push('/user/' + item.id)"
              entity="region"
              :columns="{ 'direction.name': 'Direction', name: 'Région' }"
              :hasFilter="true"
              :inline="true"
              :edit="editMode"
            ></many2one-field>
            <many2one-field
              label="Responsable de projet"
              v-model="project.owner"
              :fetch="$Api.User.fetchUsers"
              reference="name"
              @click="(item) => $router.push('/user/' + item.id)"
              entity="user"
              :columns="userColumns"
              :hasFilter="true"
              :inline="true"
              :edit="editMode"
            ></many2one-field>
            <date-field
              label="Date de début de projet"
              v-model="project.startDate"
              :edit="editMode"
              data-test="sel-project-start-date"
            ></date-field>
            <date-field
              label="Date de fin de projet"
              v-model="project.endDate"
              :edit="editMode"
              data-test="sel-project-end-date"
            ></date-field>
            <selector-field
              :options="$Api.Project.fetchActivities"
              label="Calcul d'activité"
              v-model="project.activity"
              :inline="true"
              :edit="editMode"
            ></selector-field>
            <checkbox-field
              label="Cumuler M / M-1"
              :edit="editMode"
              v-model="project.doubleMonth" />

            <div class="panel">
              <panel-header
                title="Codes SAP"
                @add="addOtp"
                :fetch="$Api.Otp.fetchAvailableOtps"
                :columns="{ otp: 'Code SAP' }"
                :hasFilter="true"
              >
                <button
                  slot="activator"
                  data-test="sel-user-add-imputation"
                  class="button is-small is-success"
                >
                  <font-awesome-icon icon="plus" />
                </button>
              </panel-header>
              <div class="panel-block">
                <table class="table is-fullwidth">
                  <tbody>
                    <tr v-for="(o, index) in project.otps" :key="index">
                      <td class="has-text-left">{{ o.otp }}</td>
                      <td>
                        <div class="is-pulled-right">
                          <button
                            class="button is-small is-danger"
                            @click="removeOtp(o)"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>

        <SheetsTab
          class="panel-block is-block no-border"
          v-if="currentTab == 1"
          :id="project.id"
        />

        <RevenuesTab ref="revenuesTab"
          v-if="currentTab == 2"
          :project="project"
          :id="id"
          v-model="orders"
        />

        <PrestationsTab
          ref="prestationsTab"
          v-model="prestations"
          :project="project"
          v-if="currentTab == 3"
        />
      </div>

      <modal ref="cloneModal" @create="clone" title="Copie d'un projet">
        <text-field label="Nom du nouveau projet" v-model="newProjectName" :inline="false" :edit="true" required></text-field>
      </modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PrestationsTab from './tabs/Prestations'
import RevenuesTab from './tabs/Revenues'
import SheetsTab from './tabs/Sheets'

export default {
  name: 'project',
  components: {
    PrestationsTab,
    RevenuesTab,
    SheetsTab
  },
  props: ['id'],
  computed: {
    ...mapGetters({
      editMode: 'App/isEdition',
      options: 'Auth/getOptions',
    }),
    otp () {
      return (this.project.otp) ? this.project.otp : {}
    },
    region () {
      return (this.project.region) ? this.project.region : {}
    },
    direction () {
      return (this.region.direction) ? this.region.direction : {}
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  data() {
    return {
      currentTab: 0,
      project: {},
      userColumns: {
        name: 'Nom',
        mail: 'E-Mail',
        'role.name': 'Role',
        service: 'Service',
      },
      prestations: null,
      orders: null,
      newProjectName: ''
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get(`/projects/${this.id}`).then((response) => {
          this.$store.dispatch('App/setEditing', false);
          this.project = response.data
        });

      if(this.currentTab === 2) {
        this.$refs.revenuesTab.fetchData()
      }
      if(this.currentTab === 3) {
        this.$refs.prestationsTab.fetchData()
      }
    },
    save() {
      if(this.prestations) this.project.prestations = this.prestations
      if(this.orders) this.project.orders = this.orders

      return axios.put(`/projects/${this.id}`, this.project)
        .then(() => {
          this.fetchData();
        })
    },
    edit() {
      this.$store.dispatch('App/setEditing', true);
    },
    cancel() {
      this.fetchData();
      if(this.currentTab === 2) {
        this.$refs.revenuesTab.cancel()
      }

    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/projects/${this.id}`).then((res) => {
            if (res.data.success) {
              this.$router.replace("/projects");
            }
          })
      );
    },
    openTab(val) {
      this.currentTab = val
    },
    addOtp(otp) {
      otp.project = this.project
      axios.put(`/otps/${otp.id}`, otp).then(() => {
        this.fetchData();
      });
    },
    removeOtp(otp) {
      this.$awn.confirm(
        'Êtes-vous sûr de vouloir supprimer cet élément ?',
        () => {
          otp.project = null
          axios
            .put(`/otps/${otp.id}`, otp)
            .then(() => {
              this.fetchData();
            });
        },
      );
    },
    openCloneModal()
    {
      this.$refs.cloneModal.open();
    },
    clone() {
      const data = {
        projectId: this.id,
        projectName: this.newProjectName
      }
      axios.post(`/projects/clone`, data).then((response) => {
        this.$router.push('/project/' + response.data)
        this.$refs.cloneModal.close();
      })
    }
  }
}
</script>

<style scoped lang="scss">
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.no-border {
  border: none;
  div {
    width: 100%;
    span {
      width: 30%;
      display: inline-block;
    }
  }
}
</style>
