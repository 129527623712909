<template>
  <div class="panel infos">
    <div class="panel-block is-block no-border">
      <hot-table
        ref="spreadsheet"
        :settings="settings"
        style="min-height: 50vh; overflow: auto"
      ></hot-table>
    </div>
  </div>
</template>
<script>
import { HotTable } from "@handsontable/vue";

export default {
  name: "orders-import",
  components: {
    HotTable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    columns: {},
  },
  data: function () {
    return {
      settings: {
        data: [], // Initial data during init.
        colHeaders: [],
        rowHeaders: true,
        stretchH: "all",
        minSpareRows: 1,
        columns: [],
        afterChange: () => {
          if (this.$refs.spreadsheet.hotInstance) {
            let newCells = [];
            this.$refs.spreadsheet.hotInstance
              .getSourceData()
              .forEach((row, i) => {
                row.forEach((cell, j) => {
                  if(!cell || typeof cell === 'number') return;

                  let value = cell.replaceAll(/[\r\t\n]/g, "").trim();
                  if (this.columnsDefinition[j].type === 'numeric' && typeof cell === 'string')
                    value = this.formatNumber(value);
                  if (cell != value) {
                    newCells.push([i, j, value]);
                  }
                });
              });

            if (newCells.length > 0) {
              this.$refs.spreadsheet.hotInstance.setDataAtCell(newCells);
            }
            this.data = this.$refs.spreadsheet.hotInstance
              .getSourceData()
              .map((d) =>
                d.map((c) => (c && typeof c !== "number" ? c.trim() : c))
              );
            this.$emit(
              "input",
              this.data.map((rowData) => {
                let obj = {};

                for (let i = 0; i < rowData.length; i++) {
                  let key = this.columnsDefinition[i].key;
                  obj[key] = rowData[i];
                }
                return obj;
              })
            );
          }
          this.validate();
        },
        afterRender: () => {
          this.validate();
        },
        afterValidate: (isValid, value, row, prop) => {
          if (!isValid) {
            this.$set(this.invalidLines, row + "_" + prop, true);
          } else {
            this.$delete(this.invalidLines, row + "_" + prop);
          }
        },
      },
      validLines: 0,
      columnsDefinition: [],
      data: [],
      invalidLines: {},
      errors: []
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    linesCount() {
      return this.data.reduce(
        (acc, val) => acc + (val.slice(1).some((e) => e != "") ? 1 : 0),
        0
      );
    },
    valid() {
      return Object.keys(this.invalidLines).length === 0;
    },
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    },
  },
  methods: {
    validate() {
      if (!this.$refs.spreadsheet.hotInstance) {
        return;
      }
      const spreadsheet = this.$refs.spreadsheet.hotInstance;

      const lineCount = this.data.length - 1;
      this.validLines = 0;
      this.errors.splice(0);
      for (let row = 0; row < lineCount; row++) {
        const rowData = this.data[row];

        // Skip empty lines
        if (!rowData.slice(1).some((e) => e != "")) {
          continue;
        }

        let validLine = true;
        // Validate each columns.
        for (let col = 0; col < this.columnsDefinition.length; col++) {
          const cellData = rowData[col];

          let isValid = false;

          if(this.columnsDefinition[col].type === 'numeric' && cellData === 0) {
            isValid = true;
          } else if(this.columnsDefinition[col].type === 'numeric' && typeof cellData !== 'number') {
            this.errors.splice(this.errors.length, 0, {row: row, message: `La valeur du champ ${this.columnsDefinition[col].label} n'est pas valide.`});
            isValid = false;
          } else if (this.columnsDefinition[col].required && (cellData == "" || cellData === null)) {
            this.$set(this.invalidLines, row + "_" + col, true);
            isValid = false;
            this.errors.splice(this.errors.length, 0, {row: row, message: `Le champ ${this.columnsDefinition[col].label} est obligatoire.`});
          } else if (this.columnsDefinition[col].validator) {
            isValid = this.columnsDefinition[col].validator(cellData);
            this.errors.splice(this.errors.length, 0, {row: row, message: `La valeur du champ ${this.columnsDefinition[col].label} n'est pas valide.`});
          } else {
            isValid = true;
          }

          if (validLine) {
            validLine = isValid;
          }

          // Change cell rendering if possible.
          const cell = spreadsheet.getCell(row, col);
          if (!cell) {
            continue;
          }

          if (
            validLine &&
            this.columnsDefinition[col].required &&
            this.invalidLines[row + "_" + col]
          ) {
            this.$delete(this.invalidLines, row + "_" + col);
          }

          cell.style.background = isValid ? "#CEC" : "#F00";
        }
        if (validLine) {
          this.validLines++;
        }
        this.$emit('errors', this.errors);
      }
    },
    refresh() {
      this.columnsDefinition.splice(0, this.columnsDefinition.length);
      this.settings.data.splice(0, this.settings.data.length);

      this.columns.forEach((c) => this.columnsDefinition.push(c));

      this.settings.colHeaders = this.columnsDefinition.map((c) => c.label);
      this.settings.columns = this.columnsDefinition.map((c) => {
        return {
          label: c.label,
          readOnly: c.readOnly,
          type: c.type,
          dateFormat: c.dateFormat || "MM/DD/YYYY",
          correctFormat: c.correctFormat || false,
        };
      });

      Object.values(this.value).forEach((row) => {
        const cells = [];

        this.columnsDefinition.forEach((column) => {
          cells.push(row[column.key]);
        });

        this.settings.data.push(cells);
      });
      if (this.value.length === 0) {
        this.settings.data.push(Array(this.columnsDefinition.length).fill(""));
      }
    },
    formatNumber(number)
    {
      number = number.replaceAll(/\s/g, '').trim();
      let occurrence = 0;
      do {
        occurrence = (number.match(/,/g)||[]).length + (number.match(/\./g)||[]).length;
        if(occurrence > 1)
        {
          for (let i = 0; i < number.length; i++) {
            if (number.charAt(i) === '.' || number.charAt(i) === ',') {
              number = number.slice(0,i) + number.slice(i+1);
              break;
            }
          }
        }
      }
      while(occurrence > 1)
      return number;
    }
  },
};
</script>
<style>
.overlay {
  position: relative;
}

.overlay .shadow {
  position: absolute;
  z-index: 150;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.ht_clone_top {
  z-index: 29;
}
</style>
